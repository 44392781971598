import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "API"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "api",
      "style": {
        "position": "relative"
      }
    }}>{`API`}</h1>
    <h2 {...{
      "id": "ts-terraformprovider",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "https://github.com/ts-terraform/ts-terraform/tree/master/packages/%40ts-terraform/provider"
      }}><inlineCode parentName="a">{`@ts-terraform/provider`}</inlineCode></a></h2>
    <h3 {...{
      "id": "provider",
      "style": {
        "position": "relative"
      }
    }}>{`Provider`}</h3>
    <p><inlineCode parentName="p">{`Provider`}</inlineCode>{` allows for interacting with a Terraform provider using gRPC.`}</p>
    <pre {...{
      "className": "shiki twoslash"
    }}><div parentName="pre" {...{
        "className": "language-id"
      }}>{`ts`}</div><div parentName="pre" {...{
        "className": "code-container"
      }}><code parentName="div"><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`export`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`declare`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`const`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#FAFBFC"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "const createProvider: (binary: string, opts?: Options | undefined) => Promise&lt;Provider&lt;ProviderConfigType>>"
            }}>{`createProvider`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` (`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) binary: string"
            }}>{`binary`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`: `}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`string`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) opts: Options | undefined"
            }}>{`opts`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`?: `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "interface Options"
            }}>{`Options`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`|`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`undefined`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`) `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`=>`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "interface Promise&lt;T>"
            }}>{`Promise`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "class Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`Provider`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "interface ProviderConfigType"
            }}>{`ProviderConfigType`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>>`}</span>{`

`}<span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`export`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`declare`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`function`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "function codegen(provider: Provider): string"
            }}>{`codegen`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`(`}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) provider: Provider&lt;ProviderConfigType>"
            }}>{`provider`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "class Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`Provider`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`)`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`string`}</span>{`

`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`export interface `}<data-lsp parentName="span" {...{
              "lsp": "interface Options"
            }}>{`Options`}</data-lsp>{` {`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#6A737D"
            }
          }}>{`/** If true, the provider's debug messages will be printed on stderr */`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#FAFBFC"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) Options.debug?: boolean | undefined"
            }}>{`debug`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`?:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#FAFBFC"
            }
          }}>{`boolean`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`export`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`interface`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "interface ProviderConfigType"
            }}>{`ProviderConfigType`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` {`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) ProviderConfigType.providerSchema: object"
            }}>{`providerSchema`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}>{`object`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) ProviderConfigType.dataSourceSchemas: Record&lt;string, object>"
            }}>{`dataSourceSchemas`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "type Record&lt;K extends string | number | symbol, T> = { [P in K]: T; }"
            }}>{`Record`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`string`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`, `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}>{`object`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) ProviderConfigType.resourceSchemas: Record&lt;string, object>"
            }}>{`resourceSchemas`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "type Record&lt;K extends string | number | symbol, T> = { [P in K]: T; }"
            }}>{`Record`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`string`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`, `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}>{`object`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) ProviderConfigType.dataSourceStateSchemas: Record&lt;string, object>"
            }}>{`dataSourceStateSchemas`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "type Record&lt;K extends string | number | symbol, T> = { [P in K]: T; }"
            }}>{`Record`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`string`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`, `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}>{`object`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) ProviderConfigType.resourceStateSchemas: Record&lt;string, object>"
            }}>{`resourceStateSchemas`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "type Record&lt;K extends string | number | symbol, T> = { [P in K]: T; }"
            }}>{`Record`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`string`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`, `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}>{`object`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`export`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`declare`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`class`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "class Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`Provider`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`extends`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "interface ProviderConfigType"
            }}>{`ProviderConfigType`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` = `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "interface ProviderConfigType"
            }}>{`ProviderConfigType`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`> {`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`get`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.providerSchema: ObjectType&lt;ObjectProperties>"
            }}>{`providerSchema`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`()`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) interface ObjectType&lt;T extends ObjectProperties>&#13;import ObjectType"
            }}>{`ObjectType`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) type ObjectProperties = {&#13;    [key: string]: BooleanType | NullType | NumberType | StringType | UndefinedType | ArrayType&lt;SchemaType> | ... 29 more ... | (TupleType&lt;...> &amp; ... 1 more ... &amp; {&#13;        ...;&#13;    });&#13;}&#13;import ObjectProperties"
            }}>{`ObjectProperties`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`get`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.dataSourceSchemas: Record&lt;string, ObjectType&lt;ObjectProperties>>"
            }}>{`dataSourceSchemas`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`()`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "type Record&lt;K extends string | number | symbol, T> = { [P in K]: T; }"
            }}>{`Record`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`string`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`, `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) interface ObjectType&lt;T extends ObjectProperties>&#13;import ObjectType"
            }}>{`ObjectType`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) type ObjectProperties = {&#13;    [key: string]: BooleanType | NullType | NumberType | StringType | UndefinedType | ArrayType&lt;SchemaType> | ... 29 more ... | (TupleType&lt;...> &amp; ... 1 more ... &amp; {&#13;        ...;&#13;    });&#13;}&#13;import ObjectProperties"
            }}>{`ObjectProperties`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>>`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`get`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.resourceSchemas: Record&lt;string, ObjectType&lt;ObjectProperties>>"
            }}>{`resourceSchemas`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`()`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "type Record&lt;K extends string | number | symbol, T> = { [P in K]: T; }"
            }}>{`Record`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`string`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`, `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) interface ObjectType&lt;T extends ObjectProperties>&#13;import ObjectType"
            }}>{`ObjectType`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) type ObjectProperties = {&#13;    [key: string]: BooleanType | NullType | NumberType | StringType | UndefinedType | ArrayType&lt;SchemaType> | ... 29 more ... | (TupleType&lt;...> &amp; ... 1 more ... &amp; {&#13;        ...;&#13;    });&#13;}&#13;import ObjectProperties"
            }}>{`ObjectProperties`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>>`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`get`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.dataSourceStateSchemas: Record&lt;string, ObjectType&lt;ObjectProperties>>"
            }}>{`dataSourceStateSchemas`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`()`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "type Record&lt;K extends string | number | symbol, T> = { [P in K]: T; }"
            }}>{`Record`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`string`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`, `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) interface ObjectType&lt;T extends ObjectProperties>&#13;import ObjectType"
            }}>{`ObjectType`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) type ObjectProperties = {&#13;    [key: string]: BooleanType | NullType | NumberType | StringType | UndefinedType | ArrayType&lt;SchemaType> | ... 29 more ... | (TupleType&lt;...> &amp; ... 1 more ... &amp; {&#13;        ...;&#13;    });&#13;}&#13;import ObjectProperties"
            }}>{`ObjectProperties`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>>`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`get`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.resourceStateSchemas: Record&lt;string, ObjectType&lt;ObjectProperties>>"
            }}>{`resourceStateSchemas`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`()`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "type Record&lt;K extends string | number | symbol, T> = { [P in K]: T; }"
            }}>{`Record`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`string`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`, `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) interface ObjectType&lt;T extends ObjectProperties>&#13;import ObjectType"
            }}>{`ObjectType`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) type ObjectProperties = {&#13;    [key: string]: BooleanType | NullType | NumberType | StringType | UndefinedType | ArrayType&lt;SchemaType> | ... 29 more ... | (TupleType&lt;...> &amp; ... 1 more ... &amp; {&#13;        ...;&#13;    });&#13;}&#13;import ObjectProperties"
            }}>{`ObjectProperties`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>>`}</span>{`

`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(method) Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.getSchema(): Promise&lt;tfplugin5.GetProviderSchema.Response>"
            }}>{`getSchema`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`()`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "interface Promise&lt;T>"
            }}>{`Promise`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "import tfplugin5"
            }}>{`tfplugin5`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`.`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "any"
            }}>{`GetProviderSchema`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`.`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "any"
            }}>{`Response`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>`}</span>{`

`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(method) Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.applyResourceChange&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, priorState: ProviderConfig[&apos;resourceStateSchemas&apos;][Name], plannedState: ProviderConfig[&apos;resourceStateSchemas&apos;][Name], options?: {&#13;    private?: Record&lt;string, unknown> | undefined;&#13;} | undefined): Promise&lt;{&#13;    newState: ProviderConfig[&apos;resourceStateSchemas&apos;][Name];&#13;    private: Record&lt;string, unknown>;&#13;}>"
            }}>{`applyResourceChange`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.applyResourceChange&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, priorState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], plannedState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], options?: {&#13;    ...;&#13;} | undefined): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`extends`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) type StringKeyOf&lt;T> = keyof T extends string ? string &amp; keyof T : never&#13;import StringKeyOf"
            }}>{`StringKeyOf`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'resourceStateSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`]>>(`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) typeName: Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>"
            }}>{`typeName`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.applyResourceChange&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, priorState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], plannedState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], options?: {&#13;    ...;&#13;} | undefined): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) priorState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name]"
            }}>{`priorState`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'resourceStateSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`][`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.applyResourceChange&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, priorState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], plannedState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], options?: {&#13;    ...;&#13;} | undefined): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`],`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) plannedState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name]"
            }}>{`plannedState`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'resourceStateSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`][`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.applyResourceChange&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, priorState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], plannedState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], options?: {&#13;    ...;&#13;} | undefined): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`],`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) options: {&#13;    private?: Record&lt;string, unknown> | undefined;&#13;} | undefined"
            }}>{`options`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`?:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` {`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`      `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) private?: Record&lt;string, unknown> | undefined"
            }}>{`private`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`?:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "type Record&lt;K extends string | number | symbol, T> = { [P in K]: T; }"
            }}>{`Record`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`string`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`, `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}>{`unknown`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    },`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  )`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "interface Promise&lt;T>"
            }}>{`Promise`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<{`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) newState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name]"
            }}>{`newState`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'resourceStateSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`][`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.applyResourceChange&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, priorState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], plannedState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], options?: {&#13;    ...;&#13;} | undefined): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) private: Record&lt;string, unknown>"
            }}>{`private`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "type Record&lt;K extends string | number | symbol, T> = { [P in K]: T; }"
            }}>{`Record`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`string`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`, `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}>{`unknown`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  }>`}</span>{`

`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(method) Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.configure(config: ProviderConfig[&apos;providerSchema&apos;]): Promise&lt;tfplugin5.Configure.Response>"
            }}>{`configure`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`(`}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) config: ProviderConfig[&quot;providerSchema&quot;]"
            }}>{`config`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'providerSchema'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`])`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "interface Promise&lt;T>"
            }}>{`Promise`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "import tfplugin5"
            }}>{`tfplugin5`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`.`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "any"
            }}>{`Configure`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`.`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "any"
            }}>{`Response`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>`}</span>{`

`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(method) Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.importResourceState&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, id: string): Promise&lt;{&#13;    typeName: Name;&#13;    state: ProviderConfig[&apos;resourceStateSchemas&apos;][Name];&#13;    private?: Record&lt;string, unknown>;&#13;}[]>"
            }}>{`importResourceState`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.importResourceState&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, id: string): Promise&lt;{&#13;    typeName: Name;&#13;    state: ProviderConfig[&quot;resourceStateSchemas&quot;][Name];&#13;    private?: Record&lt;...> | undefined;&#13;}[]>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`extends`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) type StringKeyOf&lt;T> = keyof T extends string ? string &amp; keyof T : never&#13;import StringKeyOf"
            }}>{`StringKeyOf`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'resourceStateSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`]>>(`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) typeName: Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>"
            }}>{`typeName`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.importResourceState&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, id: string): Promise&lt;{&#13;    typeName: Name;&#13;    state: ProviderConfig[&quot;resourceStateSchemas&quot;][Name];&#13;    private?: Record&lt;...> | undefined;&#13;}[]>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) id: string"
            }}>{`id`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`string`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  )`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "interface Promise&lt;T>"
            }}>{`Promise`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    {`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`      `}</span><span parentName="code" {...{
            "style": {
              "color": "#FAFBFC"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) typeName: Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>"
            }}>{`typeName`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`: `}</span><span parentName="code" {...{
            "style": {
              "color": "#FAFBFC"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.importResourceState&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, id: string): Promise&lt;{&#13;    typeName: Name;&#13;    state: ProviderConfig[&quot;resourceStateSchemas&quot;][Name];&#13;    private?: Record&lt;...> | undefined;&#13;}[]>"
            }}>{`Name`}</data-lsp></span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`      `}</span><span parentName="code" {...{
            "style": {
              "color": "#FAFBFC"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) state: ProviderConfig[&quot;resourceStateSchemas&quot;][Name]"
            }}>{`state`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`: `}</span><span parentName="code" {...{
            "style": {
              "color": "#FAFBFC"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'resourceStateSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`][`}</span><span parentName="code" {...{
            "style": {
              "color": "#FAFBFC"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.importResourceState&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, id: string): Promise&lt;{&#13;    typeName: Name;&#13;    state: ProviderConfig[&quot;resourceStateSchemas&quot;][Name];&#13;    private?: Record&lt;...> | undefined;&#13;}[]>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`      `}</span><span parentName="code" {...{
            "style": {
              "color": "#FAFBFC"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) private?: Record&lt;string, unknown> | undefined"
            }}>{`private`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`?:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#FAFBFC"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "type Record&lt;K extends string | number | symbol, T> = { [P in K]: T; }"
            }}>{`Record`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#FAFBFC"
            }
          }}>{`string`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`, `}</span><span parentName="code" {...{
            "style": {
              "color": "#FAFBFC"
            }
          }}>{`unknown`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    }[]`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`>`}</span>{`

`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(method) Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.planResourceChange&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, priorState: ProviderConfig[&apos;resourceStateSchemas&apos;][Name], proposedNewState: ProviderConfig[&apos;resourceStateSchemas&apos;][Name], options?: {&#13;    private?: Record&lt;string, unknown> | undefined;&#13;} | undefined): Promise&lt;{&#13;    plannedState: ProviderConfig[&apos;resourceStateSchemas&apos;][Name];&#13;    plannedPrivate: Record&lt;string, unknown>;&#13;    requiresReplace: tfplugin5.IAttributePath[];&#13;}>"
            }}>{`planResourceChange`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.planResourceChange&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, priorState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], proposedNewState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], options?: {&#13;    ...;&#13;} | undefined): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`extends`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) type StringKeyOf&lt;T> = keyof T extends string ? string &amp; keyof T : never&#13;import StringKeyOf"
            }}>{`StringKeyOf`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'resourceStateSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`]>>(`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) typeName: Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>"
            }}>{`typeName`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.planResourceChange&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, priorState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], proposedNewState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], options?: {&#13;    ...;&#13;} | undefined): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) priorState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name]"
            }}>{`priorState`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'resourceStateSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`][`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.planResourceChange&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, priorState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], proposedNewState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], options?: {&#13;    ...;&#13;} | undefined): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`],`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) proposedNewState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name]"
            }}>{`proposedNewState`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'resourceStateSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`][`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.planResourceChange&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, priorState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], proposedNewState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], options?: {&#13;    ...;&#13;} | undefined): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`],`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) options: {&#13;    private?: Record&lt;string, unknown> | undefined;&#13;} | undefined"
            }}>{`options`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`?:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` {`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`      `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) private?: Record&lt;string, unknown> | undefined"
            }}>{`private`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`?:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "type Record&lt;K extends string | number | symbol, T> = { [P in K]: T; }"
            }}>{`Record`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`string`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`, `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}>{`unknown`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    },`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  )`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "interface Promise&lt;T>"
            }}>{`Promise`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<{`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) plannedState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name]"
            }}>{`plannedState`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'resourceStateSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`][`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.planResourceChange&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, priorState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], proposedNewState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], options?: {&#13;    ...;&#13;} | undefined): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) plannedPrivate: Record&lt;string, unknown>"
            }}>{`plannedPrivate`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "type Record&lt;K extends string | number | symbol, T> = { [P in K]: T; }"
            }}>{`Record`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`string`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`, `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}>{`unknown`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) requiresReplace: any[]"
            }}>{`requiresReplace`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "import tfplugin5"
            }}>{`tfplugin5`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`.`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "any"
            }}>{`IAttributePath`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[]`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  }>`}</span>{`

`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(method) Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.readDataSource&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;dataSourceSchemas&quot;], string>>(typeName: Name, config: ProviderConfig[&apos;dataSourceSchemas&apos;][Name]): Promise&lt;ProviderConfig[&apos;dataSourceStateSchemas&apos;][Name]>"
            }}>{`readDataSource`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.readDataSource&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;dataSourceSchemas&quot;], string>>(typeName: Name, config: ProviderConfig[&quot;dataSourceSchemas&quot;][Name]): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`extends`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) type StringKeyOf&lt;T> = keyof T extends string ? string &amp; keyof T : never&#13;import StringKeyOf"
            }}>{`StringKeyOf`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'dataSourceSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`]>>(`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) typeName: Name extends Extract&lt;keyof ProviderConfig[&quot;dataSourceSchemas&quot;], string>"
            }}>{`typeName`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.readDataSource&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;dataSourceSchemas&quot;], string>>(typeName: Name, config: ProviderConfig[&quot;dataSourceSchemas&quot;][Name]): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) config: ProviderConfig[&quot;dataSourceSchemas&quot;][Name]"
            }}>{`config`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'dataSourceSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`][`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.readDataSource&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;dataSourceSchemas&quot;], string>>(typeName: Name, config: ProviderConfig[&quot;dataSourceSchemas&quot;][Name]): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`],`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  )`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "interface Promise&lt;T>"
            }}>{`Promise`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'dataSourceStateSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`][`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.readDataSource&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;dataSourceSchemas&quot;], string>>(typeName: Name, config: ProviderConfig[&quot;dataSourceSchemas&quot;][Name]): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`]>`}</span>{`

`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(method) Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.readResource&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, currentState: ProviderConfig[&apos;resourceStateSchemas&apos;][Name], options?: {&#13;    private?: Record&lt;string, unknown> | undefined;&#13;} | undefined): Promise&lt;ProviderConfig[&apos;resourceStateSchemas&apos;][Name] | undefined>"
            }}>{`readResource`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.readResource&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, currentState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], options?: {&#13;    ...;&#13;} | undefined): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`extends`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) type StringKeyOf&lt;T> = keyof T extends string ? string &amp; keyof T : never&#13;import StringKeyOf"
            }}>{`StringKeyOf`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'resourceStateSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`]>>(`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) typeName: Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>"
            }}>{`typeName`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.readResource&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, currentState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], options?: {&#13;    ...;&#13;} | undefined): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) currentState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name]"
            }}>{`currentState`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'resourceStateSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`][`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.readResource&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, currentState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], options?: {&#13;    ...;&#13;} | undefined): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`],`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) options: {&#13;    private?: Record&lt;string, unknown> | undefined;&#13;} | undefined"
            }}>{`options`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`?:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` {`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`      `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(property) private?: Record&lt;string, unknown> | undefined"
            }}>{`private`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`?:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "type Record&lt;K extends string | number | symbol, T> = { [P in K]: T; }"
            }}>{`Record`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`string`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`, `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}>{`unknown`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    },`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  )`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "interface Promise&lt;T>"
            }}>{`Promise`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'resourceStateSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`][`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.readResource&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, currentState: ProviderConfig[&quot;resourceStateSchemas&quot;][Name], options?: {&#13;    ...;&#13;} | undefined): Promise&lt;...>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`] `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`|`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`undefined`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>`}</span>{`

`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(method) Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.shutdown(signal?: NodeJS.Signals | number): Promise&lt;boolean>"
            }}>{`shutdown`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`(`}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) signal: any"
            }}>{`signal`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`?:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "any"
            }}>{`NodeJS`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`.`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "any"
            }}>{`Signals`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`|`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`number`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`)`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "interface Promise&lt;T>"
            }}>{`Promise`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`boolean`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>`}</span>{`

`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(method) Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.upgradeResourceState&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, version: number, state: object): Promise&lt;ProviderConfig[&apos;resourceStateSchemas&apos;][Name]>"
            }}>{`upgradeResourceState`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.upgradeResourceState&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, version: number, state: object): Promise&lt;ProviderConfig[&quot;resourceStateSchemas&quot;][Name]>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`extends`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) type StringKeyOf&lt;T> = keyof T extends string ? string &amp; keyof T : never&#13;import StringKeyOf"
            }}>{`StringKeyOf`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'resourceStateSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`]>>(`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) typeName: Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>"
            }}>{`typeName`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.upgradeResourceState&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, version: number, state: object): Promise&lt;ProviderConfig[&quot;resourceStateSchemas&quot;][Name]>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) version: number"
            }}>{`version`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#79B8FF"
            }
          }}>{`number`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) state: object"
            }}>{`state`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}>{`object`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  )`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "interface Promise&lt;T>"
            }}>{`Promise`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'resourceStateSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`][`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.upgradeResourceState&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceStateSchemas&quot;], string>>(typeName: Name, version: number, state: object): Promise&lt;ProviderConfig[&quot;resourceStateSchemas&quot;][Name]>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`]>`}</span>{`

`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(method) Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.validateDataSourceConfig&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;dataSourceSchemas&quot;], string>>(typeName: Name, config: object): Promise&lt;tfplugin5.ValidateDataSourceConfig.Response>"
            }}>{`validateDataSourceConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.validateDataSourceConfig&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;dataSourceSchemas&quot;], string>>(typeName: Name, config: object): Promise&lt;tfplugin5.ValidateDataSourceConfig.Response>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`extends`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) type StringKeyOf&lt;T> = keyof T extends string ? string &amp; keyof T : never&#13;import StringKeyOf"
            }}>{`StringKeyOf`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'dataSourceSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`]>>(`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) typeName: Name extends Extract&lt;keyof ProviderConfig[&quot;dataSourceSchemas&quot;], string>"
            }}>{`typeName`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.validateDataSourceConfig&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;dataSourceSchemas&quot;], string>>(typeName: Name, config: object): Promise&lt;tfplugin5.ValidateDataSourceConfig.Response>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) config: object"
            }}>{`config`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}>{`object`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  )`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "interface Promise&lt;T>"
            }}>{`Promise`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "import tfplugin5"
            }}>{`tfplugin5`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`.`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "any"
            }}>{`ValidateDataSourceConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`.`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "any"
            }}>{`Response`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>`}</span>{`

`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(method) Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.validateResourceTypeConfig&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceSchemas&quot;], string>>(typeName: Name, config: object): Promise&lt;tfplugin5.ValidateResourceTypeConfig.Response>"
            }}>{`validateResourceTypeConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.validateResourceTypeConfig&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceSchemas&quot;], string>>(typeName: Name, config: object): Promise&lt;tfplugin5.ValidateResourceTypeConfig.Response>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`extends`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(alias) type StringKeyOf&lt;T> = keyof T extends string ? string &amp; keyof T : never&#13;import StringKeyOf"
            }}>{`StringKeyOf`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) ProviderConfig in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>"
            }}>{`ProviderConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`[`}</span><span parentName="code" {...{
            "style": {
              "color": "#9ECBFF"
            }
          }}>{`'resourceSchemas'`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`]>>(`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) typeName: Name extends Extract&lt;keyof ProviderConfig[&quot;resourceSchemas&quot;], string>"
            }}>{`typeName`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(type parameter) Name in Provider&lt;ProviderConfig extends ProviderConfigType = ProviderConfigType>.validateResourceTypeConfig&lt;Name extends Extract&lt;keyof ProviderConfig[&quot;resourceSchemas&quot;], string>>(typeName: Name, config: object): Promise&lt;tfplugin5.ValidateResourceTypeConfig.Response>"
            }}>{`Name`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`    `}</span><span parentName="code" {...{
            "style": {
              "color": "#FFAB70"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "(parameter) config: object"
            }}>{`config`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}>{`object`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`  )`}</span><span parentName="code" {...{
            "style": {
              "color": "#F97583"
            }
          }}>{`:`}</span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{` `}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "interface Promise&lt;T>"
            }}>{`Promise`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`<`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "import tfplugin5"
            }}>{`tfplugin5`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`.`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "any"
            }}>{`ValidateResourceTypeConfig`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`.`}</span><span parentName="code" {...{
            "style": {
              "color": "#B392F0"
            }
          }}><data-lsp parentName="span" {...{
              "lsp": "any"
            }}>{`Response`}</data-lsp></span><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`}`}</span></code></div></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      